<template>
  <div id="app">
		<header class="header">
			<h1 class="header-title">Christopher Worley</h1>
			<h2 class="header-subtitle">Economist</h2>
			<p>c h r i s ( @ ) c h r i s - w o r l e y . c o m </p>
		</header>
		<hr class="separator" />
		<div class="experience-block">
			<div class="experience-icon" style="background-color: #007aff"></div>
			<div class="experience-details">
				<div class="experience-title">Sunrun — <em>Director of Public Policy</em></div>
				<div class="experience-duration">2020 to present</div>
			</div>
		</div>
		<div class="experience-block">
			<div class="experience-icon" style="background-color: #FF8000"></div>
			<div class="experience-details">
				<div class="experience-title">Vivint Solar — <em>Director of Rate Design</em></div>
				<div class="experience-duration">2017 to 2020</div>
			</div>
		</div>
		<div class="experience-block">
			<div class="experience-icon" style="background-color: #245d38"></div>
			<div class="experience-details">
				<div class="experience-title">Colorado Energy Office — <em>Director of Policy & Research</em></div>
				<div class="experience-duration">2011 to 2017</div>
			</div>
		</div>
		<hr class="separator" />
		<div class="experience-block">
			<div class="experience-icon" style="background-color: #92A2BD"></div>
			<div class="experience-details">
				<div class="experience-title">Colorado School of Mines — <em>Adjunct Faculty</em></div>
				<div class="experience-duration">2011 to 2022</div>
			</div>
		</div>
		<div class="experience-block">
			<div class="experience-icon" style="background-color: #ffc72a"></div>
			<div class="experience-details">
				<div class="experience-title">Upper Iowa University-Online — <em>Adjunct Faculty</em></div>
				<div class="experience-duration">2011 to 2022</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 40px 0 25px;
  background-color: white; 
  padding: 0 0 10px 0;
  display: inline-block;
}
body {
  background: url('./assets/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.header {
  margin-bottom: 20px;
}

.header-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
  padding-top: 20px;
}

.header-subtitle {
  font-size: 18px;
  color: #666;
}
.separator {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
.experience-block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.experience-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.experience-details {
  flex: 1;
}

.experience-title {
  font-size: 24px;
}

.experience-duration {
  font-size: 16px;
  color: #999;
}
</style>